<template>
    <div>                     
        <input
            class="form-control"
            type="email"
            v-on:input="update"
            v-on:blur="updateOnBlur"
            v-model="value"
        >
    </div>
</template>

<script>

export default {
    props: ['question'],

    data() {
        return {
            value: this.question.value,
        }
    },

    methods: {
        updateOnBlur: function (event) {
            this.update(event, true);
        },
        update: function (event, blur = false) {
            this.value = event.target.value;

            let data = {
                key: this.question.key,
                value: this.value,
                blur: blur
            }

            this.$emit('updated', data);
        },
    }    
}
</script>